import { FC } from 'react'
import type { PropsWithChildren } from 'react'

interface Props {
  statusCode: string | number
  description?: string
}

export const ErrorBase: FC<PropsWithChildren<Props>> = ({ statusCode, description, children }) => {
  return (
    <section className="flex w-full flex-col items-center justify-center px-15 pt-100 pb-36 xxs:pb-70 xs:pt-100 xs:pb-120 sm:pt-140 sm:pb-200 md:pt-170 md:pb-250 lg:px-80 lg:pt-200 lg:pb-420">
      <h1 className="text-stroke font-heading text-88 text-transparent xs:text-130 sm:text-180 md:text-250 lg:text-300">
        {statusCode}
      </h1>
      <p className="mb-24 text-center text-24 leading-10 xs:mb-32 xs:text-30 sm:mb-40 sm:text-36 md:mb-55 md:text-48">
        {description}
      </p>
      {children}
    </section>
  )
}
