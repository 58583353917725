import { FC, useEffect, useState } from 'react'
import { GetServerSidePropsContext } from 'next'
import { isMicrositeDomain, ROUTES } from 'utils'
import { Layout } from 'components'
import { Layout as LayoutType } from 'types/graphql'
import { DataService } from 'utils/data'
import { ErrorBase } from 'components/Error/ErrorBase'
import { useRouter } from 'next/dist/client/router'
import { FlexibleLink } from 'components/Shared/FlexibleLink'
import { MainSiteLinks } from 'types/util'
import type { PropsWithChildren } from 'react'

interface Props {
  preview?: boolean
  title: string
  description: string
  layout: LayoutType
  links: MainSiteLinks
  footerType: string
}

const NotFound: FC<PropsWithChildren<Props>> = ({ preview, title, description, layout, links }) => {
  const [isMainLayout, setIsMainLayout] = useState<true>()
  const router = useRouter()

  useEffect(() => {
    if (isMicrositeDomain()) {
      router.push('/svet-bankid/404')
    } else {
      setIsMainLayout(true)
    }
  }, [])

  if (isMainLayout)
    return (
      <Layout preview={preview} title={title} description={description} data={layout} links={links}>
        <ErrorBase statusCode="404" description="This page was not found">
          <div className="flex flex-col items-center space-y-16 font-heading text-12 uppercase md:flex-row md:space-y-0 md:space-x-64">
            <FlexibleLink data={ROUTES.index}>Home</FlexibleLink>
            <FlexibleLink data={ROUTES.our_work}>Our work</FlexibleLink>
            {links.pages?.map((page, i) => (
              <FlexibleLink data={page.node} key={i}>
                {page.node.title}
              </FlexibleLink>
            ))}
            <FlexibleLink data={ROUTES.contact}>Contact</FlexibleLink>
          </div>
        </ErrorBase>
      </Layout>
    )

  return null
}

export async function getStaticProps({
  params,
  previewData = {},
  preview = false,
}: GetServerSidePropsContext) {
  const data = new DataService(previewData, 'about-us', params)
  const { layout, links } = await data.getProps()

  return {
    props: {
      preview,
      title: 'Page not found',
      description: 'Page not found',
      lang: 'en',
      layout,
      links,
    },
    revalidate: 1,
  }
}

export default NotFound
